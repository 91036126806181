import { Controller } from "stimulus";
import "dhtmlx-gantt";

export default class extends Controller {
  static values = {
    data: Array // Datos de las tareas en el gráfico Gantt
  };

  connect() {
    console.log("Gantt controller connected");

    // Configuración básica del Gantt
    gantt.config.xml_date = "%Y-%m-%d %H:%i";
    gantt.config.drag_resize = false; // Deshabilita el estiramiento de tareas
    gantt.config.drag_links = false; // Deshabilita la creación de enlaces entre tareas
    gantt.config.drag_move = false; // No se pueden mover las barras

    // Configuración de columnas para mostrar equipos y tareas
    gantt.config.columns = [
      { name: "text", label: "Equipo / Planificación", width: "*", tree: true }
    ];

    // Localización en español
    gantt.locale = {
      date: {
        month_full: [
          "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", 
          "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
        ],
        month_short: [
          "Ene", "Feb", "Mar", "Abr", "May", "Jun", 
          "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"
        ],
        day_full: [
          "Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"
        ],
        day_short: ["Dom", "Lun", "Mar", "Mié", "Jue", "Vie", "Sáb"]
      },
      labels: {
        new_task: "Nueva tarea",
        icon_save: "Guardar",
        icon_cancel: "Cancelar",
        icon_details: "Detalles",
        icon_edit: "Editar",
        icon_delete: "Eliminar",
        confirm_closing: "",
        confirm_deleting: "La tarea será eliminada. ¿Deseas continuar?",
        section_description: "Descripción",
        section_time: "Período de tiempo",
        time_unit: ["día", "día", "días"],
      }
    };

    // Deshabilitar la barra para los grupos
    gantt.templates.task_class = function (start, end, task) {
      return task.type === "group" ? "gantt_no_bar" : "";
    };

    gantt.templates.task_text = function (start, end, task) {
      return task.type === "group" ? task.text : task.text;
    };

    // Inicializar el Gantt
    gantt.init("gantt_here");

    // Cargar los datos
    this.loadData();
  }

  loadData() {
    const tasks = [];
    const links = [];

    // Crear tareas y grupos para el Gantt
    this.dataValue.forEach((assignment, index) => {
      const task = {
        id: `task-${index + 1}`,
        text: assignment.planning,
        start_date: gantt.date.parseDate(assignment.start_date, "%Y-%m-%d %H:%i"),
        end_date: gantt.date.parseDate(assignment.end_date, "%Y-%m-%d %H:%i"),
        type: "task",
        parent: `team-${assignment.team}`
      };

      // Crear grupo si no existe
      if (!tasks.find((t) => t.id === `team-${assignment.team}`)) {
        tasks.push({
          id: `team-${assignment.team}`,
          text: assignment.team,
          type: "group",
          open: true // Colapsado por defecto
        });
      }

      tasks.push(task);
    });

    console.log("Tasks for Gantt:", { data: tasks, links });

    // Cargar las tareas en el gráfico Gantt
    gantt.parse({ data: tasks, links });
  }
}
