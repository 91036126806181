import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["formulaInput"];

  connect() {
    console.log("Formula controller conectado");
  }

  insertElement(event) {
    const element = event.currentTarget.dataset.element;
    console.log(`Insertar elemento: ${element}`);

    if (this.hasFormulaInputTarget) {
      this.insertAtCursor(this.formulaInputTarget, element);
    } else {
      console.error("Target formulaInput no encontrado.");
    }
  }

  insertAtCursor(input, text) {
    const start = input.selectionStart;
    const end = input.selectionEnd;
    const currentText = input.value;

    input.value = `${currentText.substring(0, start)}${text}${currentText.substring(end)}`;
    input.selectionStart = input.selectionEnd = start + text.length;
    input.focus();
  }
}
