import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["sharingLevel", "clubsField", "teamsField", "usersField"];

  connect() {
    const initialLevel = this.sharingLevelTarget.value;

    // Marcar el botón correspondiente como activo
    this.element.querySelectorAll("[data-action='click->share-resource#selectLevel']").forEach(button => {
      if (button.dataset.level === initialLevel) {
        button.classList.add("active");
        button.dataset.selected = "true";
      }
    });

    // Mostrar los campos iniciales según el nivel
    this.showFieldsForLevel(initialLevel);
  }

  selectLevel(event) {
    const level = event.currentTarget.dataset.level;

    // Actualizar el valor del campo oculto
    this.sharingLevelTarget.value = level;

    // Desactivar todos los botones y activar el seleccionado
    this.resetButtons();
    event.currentTarget.classList.add("active");
    event.currentTarget.dataset.selected = "true";

    // Ocultar todos los campos y mostrar el seleccionado
    this.hideAllFields();
    this.showFieldsForLevel(level);
  }

  resetButtons() {
    this.element.querySelectorAll("[data-action='click->share-resource#selectLevel']").forEach(button => {
      button.classList.remove("active");
      button.dataset.selected = "false";
    });
  }

  hideAllFields() {
    if (this.hasClubsFieldTarget) this.clubsFieldTarget.classList.add("d-none");
    if (this.hasTeamsFieldTarget) this.teamsFieldTarget.classList.add("d-none");
    if (this.hasUsersFieldTarget) this.usersFieldTarget.classList.add("d-none");
  }

  showFieldsForLevel(level) {
    if (level === "clubs" && this.hasClubsFieldTarget) {
      this.clubsFieldTarget.classList.remove("d-none");
    } else if (level === "teams" && this.hasTeamsFieldTarget) {
      this.teamsFieldTarget.classList.remove("d-none");
    } else if (level === "users" && this.hasUsersFieldTarget) {
      this.usersFieldTarget.classList.remove("d-none");
    }
  }
}
