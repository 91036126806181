import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["eventsSelector", "teamsSelector", "startDate", "endDate"];

  connect() {
    this.updateVisibleSelector(); // Configurar visibilidad inicial
  }

  updateVisibleSelector() {
    const assignToEventsRadio = document.getElementById("assign_to_events");
    const assignToTeamsRadio = document.getElementById("assign_to_teams");

    if (assignToEventsRadio.checked) {
      this.showEventsSelector();
    } else if (assignToTeamsRadio.checked) {
      this.showTeamsSelector();
    }
  }

  toggleRequired(assignToEvents) {
    const eventsSelect = this.eventsSelectorTarget.querySelector("select");
    const teamsSelect = this.teamsSelectorTarget.querySelector("select");

    if (assignToEvents) {
      // Configurar "Eventos" como requerido
      this.startDateTarget.required = false;
      this.endDateTarget.required = false;
      eventsSelect.setAttribute("required", "required");
      teamsSelect.removeAttribute("required");
    } else {
      // Configurar "Equipos" como requerido
      this.startDateTarget.required = true;
      this.endDateTarget.required = false;
      eventsSelect.removeAttribute("required");
      teamsSelect.setAttribute("required", "required");
    }
  }

  showEventsSelector() {
    this.eventsSelectorTarget.style.display = "block";
    this.teamsSelectorTarget.style.display = "none";

    // Ocultar campos de fechas
    this.startDateTarget.closest(".mb-3").style.display = "none";
    this.endDateTarget.closest(".mb-3").style.display = "none";

    // Configurar "Eventos" como requerido
    this.toggleRequired(true);
  }

  showTeamsSelector() {
    this.eventsSelectorTarget.style.display = "none";
    this.teamsSelectorTarget.style.display = "block";

    // Mostrar campos de fechas
    this.startDateTarget.closest(".mb-3").style.display = "block";
    this.endDateTarget.closest(".mb-3").style.display = "block";

    // Configurar "Equipos" como requerido
    this.toggleRequired(false);
  }
}
