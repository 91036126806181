import { Controller } from "stimulus"
import Tagify from '@yaireo/tagify'

export default class extends Controller {
  connect() {
    const input = this.element
    new Tagify(input, {
      delimiters: this.data.get("delimiter") ?? ",",
      enforceWhitelist: this.data.get("enforcewhitelist") ?? false,
      whitelist: JSON.parse(this.data.get("whitelist")) ?? [],
      dropdown : {
        classname     : "color-blue",
        enabled       : 0,              // show the dropdown immediately on focus
        maxItems      : Infinity,
        position      : "text",         // place the dropdown near the typed text
        closeOnSelect : false,          // keep the dropdown open after selecting a suggestion
        highlightFirst: true
      },
      templates: {
        dropdownFooter(suggestions){
          var hasMore = suggestions.length - this.settings.dropdown.maxItems;
    
          return hasMore > 0
            ? `<footer data-selector='tagify-suggestions-footer' class="${this.settings.classNames.dropdownFooter}">
                ${hasMore} items adicionales. Refiná tu búsqueda.
              </footer>`
            : '';
        }
      },
      callbacks: {
        add: this.onTagAdded.bind(this),
        remove: this.onTagRemoved.bind(this),
        
      }
    })
  }

  onTagAdded(e) {
    console.log("Tag added:", e.detail.tag)
  }

  onTagRemoved(e) {
    console.log("Tag removed:", e.detail.tag)
  }
}