import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["colorInput", "hiddenColorField", "colorPalette"];

  connect() {
    console.log("Color Picker Controller conectado.");
  }

  updateHiddenField(event) {
    const colorPicker = event.target;
    this.hiddenColorFieldTarget.value = colorPicker.value;
  }

  selectPresetColor(event) {
    const selectedColor = event.target.dataset.color;
    
    // Actualiza el color picker y el hidden input
    this.colorInputTarget.value = selectedColor;
    this.hiddenColorFieldTarget.value = selectedColor;
  }
}
