import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["selectContainer"]

  connect() {
    // Obtener el estado inicial del checkbox y ajustar el contenedor
    const moveAllCheckbox = this.element.querySelector('[name="move_all"]');
    if (moveAllCheckbox) {
      this.toggleSelect({ target: moveAllCheckbox });
    }
  }

  toggleSelect(event) {
    const isChecked = event.target.checked;
    this.selectContainerTarget.style.display = isChecked ? "none" : "block";
  }
}
