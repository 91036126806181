import { Controller } from "stimulus";
import Sortable from "sortablejs";

export default class extends Controller {
  static targets = ["interval", "list", "add_item", "template", "totalIntervals", "totalTime", "skills", "intervalType", "setPiece"];
  static values = { index: String };

  connect() {
    this.initSortable();
    this.addDurationListeners();
    this.updateSummary();
    // Añadimos listeners sobre cada target intervalType
    this.intervalTypeTargets.forEach((intervalType) => {
      intervalType.addEventListener(
        "change",
        this.handleIntervalTypeChange.bind(this)
      );

      // Configuración inicial al cargar la página
      this.handleIntervalTypeChange({ target: intervalType });
    });
  }

  addAssociation(event) {
    event.preventDefault();
  
    const uniqueIndex = Date.now();
    
    // Clonar el contenido de la plantilla para evitar efectos secundarios
    let templateContent = this.templateTarget.content.cloneNode(true).firstElementChild;
    let content = templateContent.innerHTML.replace(/NEW_RECORD/g, uniqueIndex);
  
    // Insertar el nuevo contenido
    this.listTarget.insertAdjacentHTML('beforeend', content);

    // Asignar listener al nuevo campo intervalType
    const newInterval = this.listTarget.lastElementChild;

    const intervalTypeField = newInterval.querySelector("[data-intervals-form-target='intervalType']");
    if (intervalTypeField) {
      intervalTypeField.addEventListener(
        "change",
        this.handleIntervalTypeChange.bind(this)
      );

      // Configuración inicial
      this.handleIntervalTypeChange({ target: intervalTypeField });
    }
  
    this.initSortable();
    this.addDurationListeners(); // Asegurarnos de que cada campo tenga solo un listener
  
    this.updateSummary(); // Actualizar el resumen después de agregar un nuevo intervalo
  }
  
  removeAssociation(event) {
    event.preventDefault();
    event.stopPropagation();

    let item = event.target.closest(".nested-fields");

    if (item) {
      let requiredInputs = item.querySelectorAll("input[required]");
      requiredInputs.forEach((input) => input.removeAttribute('required'));
      item.querySelector("input[name*='_destroy']").value = 1;
      item.style.display = 'none';
      this.updateSummary(); // Actualizar resumen al eliminar un intervalo
    } else {
      console.error("Error: unable to find .nested-fields element.");
    }
  }

  initSortable() {
    // Destruir la instancia anterior si ya existe
    if (this.sortable) {
      this.sortable.destroy();
    }

    // Inicializa sortable
    this.sortable = Sortable.create(this.listTarget, {
      animation: 150,
      ghostClass: 'blue-background-class',
      handle: '.sortable-handle',
      onEnd: this.end.bind(this)
    });
  }

  end(event) {
    // Actualiza las posiciones de los intervalos
    this.listTarget.querySelectorAll('.nested-fields').forEach((el, index) => {
      const positionField = el.querySelector('.position-field');
      if (positionField) {
        positionField.value = index + 1;
      }
    });
  }

  addDurationListeners() {
    // Añadir listeners a los campos de duración para que actualicen el resumen dinámicamente
    this.listTarget.querySelectorAll("input[id*='duration_seconds']").forEach((input) => {
      input.addEventListener('input', () => {
        this.updateSummary();
      });
    });
  }

  updateSummary() {
    // Contar los intervalos visibles (no eliminados)
    let intervalsCount = 0;
    let totalSeconds = 0;

    this.listTarget.querySelectorAll('.nested-fields').forEach((interval) => {
      // Excluir los intervalos marcados para ser eliminados
      const destroyField = interval.querySelector("input[name*='_destroy']");
      if (destroyField && destroyField.value === "1") return;

      intervalsCount += 1;

      // Sumar el tiempo total de los intervalos
      const durationField = interval.querySelector("input[id*='duration_seconds']");
      if (durationField && durationField.value) {
        const timeParts = durationField.value.split(":");
        const minutes = parseInt(timeParts[0], 10);
        const seconds = parseInt(timeParts[1], 10);
        totalSeconds += (minutes * 60) + seconds;
      }
    });

    // Convertir segundos totales a formato HH:MM:SS
    const totalHours = Math.floor(totalSeconds / 3600);
    const totalMinutes = Math.floor((totalSeconds % 3600) / 60);
    const remainingSeconds = totalSeconds % 60;
    const formattedTime = `${String(totalHours).padStart(2, '0')}:${String(totalMinutes).padStart(2, '0')} hs.`;

    // Actualizar el DOM
    this.totalIntervalsTarget.textContent = intervalsCount;
    this.totalTimeTarget.textContent = formattedTime;
  }

  handleIntervalTypeChange(event) {
    const intervalField = event.target.closest(".nested-fields");
    if (!intervalField) return;

    const selectedType = event.target.value;
    const skillContainer = intervalField.querySelector("[data-intervals-form-target='skills']");
    const setPieceContainer = intervalField.querySelector("[data-intervals-form-target='setPiece']");

    const skillField = skillContainer?.querySelector(".skill-select");
    const setPieceField = setPieceContainer?.querySelector(".set_piece-select");

    // Resetear visibilidad y requisitos
    skillContainer.style.display = "none";
    setPieceContainer.style.display = "none";
    skillField?.removeAttribute("required");
    setPieceField?.removeAttribute("required");

    // Actualizar según el valor seleccionado
    if (selectedType === "technical_skills") {
      skillContainer.style.display = "block";
      skillField?.setAttribute("required", "required");
    } else if (selectedType === "set_pieces") {
      setPieceContainer.style.display = "block";
      setPieceField?.setAttribute("required", "required");
    }
  }

  
}
